import React, { useState, useEffect } from "react"
import FilterBox from './FilterBox';
import {useSearchParams} from 'react-router-dom';
import getTitleFromSlug from './../../services/getTitleFromSlug';

const Filter = ({ data, maxItems, lang, filterProjects, clearFilter, perPage, translations, filterActionClose, setFilterActionClose }) => {
    let [ load, setLoad ] = useState(false);
    let [ active, setActive ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    let [ translatesActiveFilters, setTranslatesActiveFilters ] = useState([]);

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            setActive(false);
            setFilterActionClose(false);
        }
    }, [filterActionClose])

    const getCountProjects = () => {
        return maxItems + ` ${translations.projects}`;
    }

    const removeFilter = (item) => {
        let valInUrl = searchParams.get(item.categorySlug);
        let array = valInUrl.split(',');
            let index = array.indexOf(item.slug);
            if (index !== -1) {
                array.splice(index, 1);
            }
            searchParams.set(item.categorySlug, array.toString())
            if(array.length == 0) {
                searchParams.delete(item.categorySlug);
            }
            setSearchParams(searchParams);
            filterProjects();
    }

    useEffect(() => {
        let newTranslates = [];
        searchParams.forEach((value, category) => {
            if(category != "s") {
                let array = value.split(",");
                for(let i = 0; i<array.length; i++) {
                    let newData = getTitleFromSlug(data, category, array[i])
                    newTranslates = [...newTranslates, {
                        name: newData.name,
                        slug: newData.slug,
                        categorySlug: category
                    }]
                }
            }
        })
        setTranslatesActiveFilters(newTranslates);
    }, [searchParams])

    return (
        <div className="filter-wrapper">
            <div className="filter-toggle-wrapper">
                <div className="toggle-wrapper" onClick={() => setActive(!active)}>
                    <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_1_2052)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.96555 3L24 3V4L9.96555 4C9.72741 5.71007 8.2823 7 6.5 7C4.7177 7 3.27259 5.71007 3.03445 4H0V3H3.03445C3.27259 1.28993 4.7177 0 6.5 0C8.2823 0 9.72741 1.28993 9.96555 3ZM4 3.5C4 4.9 5.1 6 6.5 6C7.9 6 9 4.9 9 3.5C9 2.1 7.9 1 6.5 1C5.1 1 4 2.1 4 3.5Z" fill="#373737"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 11.5H20.9502C20.6707 9.83988 19.2474 8.59998 17.5 8.59998C15.5477 8.59998 14 10.1477 14 12.1V11.5H0V12.5H14V12.1C14 14.0523 15.5477 15.6 17.5 15.6C19.3169 15.6 20.7833 14.2595 20.978 12.5H24V11.5ZM20 12.1C20 10.7 18.9 9.59998 17.5 9.59998C16.1 9.59998 15 10.7 15 12.1C15 13.5 16.1 14.6 17.5 14.6C18.9 14.6 20 13.5 20 12.1Z" fill="#373737"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 20L13.9656 20C13.7274 18.2899 12.2823 17 10.5 17C8.7177 17 7.27259 18.2899 7.03445 20H0V21H7.03445C7.27259 22.7101 8.7177 24 10.5 24C12.2823 24 13.7274 22.7101 13.9656 21L24 21V20ZM10.5 23C9.1 23 8 21.9 8 20.5C8 19.1 9.1 18 10.5 18C11.9 18 13 19.1 13 20.5C13 21.9 11.9 23 10.5 23Z" fill="#373737"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1_2052">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p>{translations.filter} ({translatesActiveFilters.length})</p>
                </div>
                <div className="results-wrapper">
                    {translatesActiveFilters && translatesActiveFilters.map((item, index) => (
                        <div className="box" key={index} onClick={() => removeFilter(item)}>
                            <p>{item.name}</p>
                            <div className="icon">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group 41">
                                    <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M14.7424 14L20.9646 20.2222L20.2575 20.9293L14.0353 14.7071L7.74239 21L7.03528 20.2929L13.3282 14L7.03528 7.70711L7.74239 7L14.0353 13.2929L20.2575 7.07066L20.9646 7.77777L14.7424 14Z" fill="#373737"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="clear-wrapper">
                <p onClick={() => clearFilter()}>{translations.clear_filters}</p>
                <p>{getCountProjects()}</p>
            </div>
            <FilterBox active={active} perPage={perPage} close={() => setActive(false)} data={data} maxItems={maxItems} clear={() => clearFilter()} translatesActiveFilters={translatesActiveFilters} lang={lang} filterProjects={() => filterProjects()} translations={translations} />
        </div>
    )
}

export default Filter;