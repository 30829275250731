import React, { useRef } from "react"
import Editor from './../../components/editor';
import Slider from "react-slick";
import ArrowIco from './../../svg-components/ArrowIco';

const Banner = ( { editor, video, type, slides } ) => {
    let slider = useRef();

    let settings = {
        dots: true,
        arrows: true,
        autoplay: false,
        infinite: true,
        pauseOnHover: false,
        autoplaySpeed: 6400,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        // customPaging: function (i) {
        //     return (
        //         <div key={i}>
        //             <div className="line"></div>
        //         </div>
        //     );
        // },
    };

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
        <div
            className={className + ' custom-arrow'}
            style={{ ...style }}
            onClick={onClick} 
            // onClick={() => { onClick(); slider.current.slickPause() } }
            // onKeyDown={ (e) => handleKeyDown(e, onClick)} 
            tabIndex="0"
            role="button">
                <ArrowIco />
        </div>
        );
    }
      
    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className + ' custom-arrow'}
                style={{ ...style }}
                onClick={onClick} 
                // onClick={() => { onClick(); slider.current.slickPause() } }
                // onKeyDown={ (e) => handleKeyDown(e, onClick)}
                tabIndex="0" 
                role="button">
                    <ArrowIco />
            </div>
        );
    }

    return (
        <>
            {type == "video" &&
                <section className="banner">
                    <video width="1920" height="900" autoPlay loop muted playsInline>
                        <source src={video} type="video/mp4" />
                    </video>
                    <Editor html={editor} />
                </section>
            }
            {type == "slider" &&
                <section className="slider">
                    <Slider {...settings} ref={slider}>
                        {slides && slides.map((el, i) => (
                            <div className="slide-wrapper" key={i}>
                                <div className="image-wrapper">
                                    <img src={el.image.sizes['1920']} alt={ el.image.alt } />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </section>
            }
        
        </>
    )
}

export default Banner;