import { useState, useEffect } from "react"
import {useSearchParams} from 'react-router-dom';
import Get from './../../services/get';

export default props => {
    let [ load, setLoad ] = useState( false );
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if ( !load ) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            setLoad(false);
        }
    }, [props.reset])

    const changeVal = (val) => {
        if(val) {
            searchParams.set(props.tax, props.slug)
                setSearchParams(searchParams);
        }else{
            searchParams.delete(props.tax);
            setSearchParams(searchParams);
        }
    }

    const removeFromUrlTax = () => {
        searchParams.delete(props.tax);
        setSearchParams(searchParams);
    }

    const getValue = () => {
        let val = Get(props.tax);
        if(val ) {
            if(val.includes(props.slug)) {
                return 1;
            }else{
                return 0;
            }
        }else 
        return 0;
    }
    
  return (
      <div className="radio-wrapper">
        <input type="radio" name={props.tax} value={getValue()} checked={getValue() == "1"} onChange={ e => { changeVal(e.target.value) }} />
        <label for={props.tax}>{props.text}</label>
      </div>
  )
}
