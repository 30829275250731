import React, { useState, useEffect } from "react"
import Header from './header';
import Footer from './footer';
import getLangFromUrl from './../services/getLangFromUrl';
import { useLocation } from "react-router-dom";

const Layout = ( { children, translations } ) => {
    let [ lang, setLang ] = useState(false);
    let location = useLocation();

    useEffect(() => {
        setLang(getLangFromUrl(location.pathname));
    }, [location])

    useEffect(() => {
        console.log(lang)
    }, [lang])

    return (
        <div className="layout">
            <Header lang={lang} translations={translations} />
            { children }
            <Footer lang={lang} translations={translations} />
        </div>
    )

}



export default Layout;