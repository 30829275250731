const getLangFromUrl = (pathname) => {
    const langExists = ["pl", "en", "es"];
    let selectLang = "pl";
    for(let i = 0; i<langExists.length; i++) {
        if(pathname.substr(1,2).includes(langExists[i])) {
            selectLang = langExists[i];
        }
    }

    return selectLang;
}

export default getLangFromUrl;