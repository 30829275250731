import React, { useState, useEffect } from "react"
import Filter from './../../components/filter';
import Pagination from './../../components/pagination';
import Project from './../../components/project';
import getApiUrl from './../../services/getApiUrl';
import {useSearchParams} from 'react-router-dom';

const Projects = ({ perPage, lang, data, translations } ) => {
    let [ load, setLoad ] = useState(false);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ lastPage, setLastPage ] = useState(1);
    let [ maxItems, setMaxItems ] = useState(96);
    let [ items, setItems ] = useState([]);
    let [ searchActive, setSearchActive ] = useState(false);
    let [ filterActionClose, setFilterActionClose ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {
        if(!load) {
            setCurrentPage(1);
            setMaxItems(data.max_items);
            setItems(data.data);
            setLastPage(data.max_pages);
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            getData();
        }
    }, [currentPage])

    useEffect(() => {
        if(load) {
            setLoad(false);
        }
    }, [data])

    useEffect(() => {
        let s = searchParams.get("s");
        if(s && s.length >= 2) {
            setCurrentPage(1);
            getData("reset");
            setSearchActive(true);
        }
    }, [searchParams])

    const getData = async(reset = false) => {
        if(reset) {
            setItems([]);
        }
        let search = searchParams.get("s")
        let resPage;
        if(!search) {
            resPage = await fetch(`${getApiUrl()}/wp-json/v3/filter-projects?perPage=${perPage}&lang=${lang}&page=${currentPage}&${(window.location.search).substr(1)}`);
        }
        if(search && search.length >= 2) {
            resPage = await fetch(`${getApiUrl()}/wp-json/v3/search?perPage=${perPage}&lang=${lang}&page=${currentPage}&search=${search}`);
        }
        const result = await resPage.json();
        if(result && result.data && result.data.length > 0) {
            if(reset) {
                setItems(result.data)
            }else{
                setItems([...items,  ...result.data])
            }
            setMaxItems(result.max_items);
            setLastPage(result.max_pages);
        }
    }

    const clearSearch = () => {
        searchParams.delete("s");
        setSearchParams(searchParams);
        setLoad(false);
        setSearchActive(false);
    }

    const clearFilter = async() => {
        searchParams.delete("metraz");
        searchParams.delete("drzwi");
        searchParams.delete("meble");
        searchParams.delete("podlogi");
        searchParams.delete("sciany");
        searchParams.delete("wnetrza");
        searchParams.delete("s");
        // searchParams.forEach((value, category) => {
        //     console.log(category)
        //     searchParams.delete(category);
        // })
        setSearchParams(searchParams);
        setLoad(false);
    }

    const filterProjects = async () => {
        let getData = await fetch(`${getApiUrl()}/wp-json/v3/filter?perPage=${perPage}&lang=${lang}&page=${1}&${(window.location.search).substr(1)}`);
        const result = await getData.json();

        if(result) {
            setCurrentPage(1);
            setMaxItems(result.max_items);
            setItems(result.data);
            setLastPage(result.max_pages);
            setFilterActionClose(true);
        }
    }

    return (
        <section className="projects" id="projects">
            <div className="container">
                {!searchActive &&
                   <Filter data={data.filter_data} perPage={perPage} maxItems={maxItems} lang={lang} filterProjects={() => filterProjects()} clearFilter={() => clearFilter()} translations={translations} setFilterActionClose={(val) => setFilterActionClose(val)} filterActionClose={filterActionClose} />   
                }
                {searchActive &&
                    <div className="search-results-wrapper">
                        {items && items.length > 0 &&
                            <p>{translations.search_results_for} <strong>{searchParams.get("s")}</strong></p>
                        }
                        {items && items.length == 0 &&
                            <p>{translations.empty_search_results_for} <strong>{searchParams.get("s")}</strong></p>
                        }
                        <div className="clear-wrapper" onClick={() => clearSearch()}>
                            <p>{translations.clear_search_results}</p>
                        </div>
                    </div>
                }
                <div className="projects-wrapper">
                    {items && items.map((item, i) => (
                        <Project data={item} lang={lang} translations={translations} />
                    ))}
                </div>
                {items && items.length > 0 &&
                    <Pagination perPage={perPage} maxItems={maxItems} currentPage={currentPage} lastPage={lastPage} changePage={() => setCurrentPage(currentPage + 1)} translations={translations} />
                }
            </div>
        </section>
    )
}

export default Projects;