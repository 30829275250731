
import React, { useEffect, useState } from 'react';
import Breadcrumbs from './../breadcrumbs';
import PreviewImage from './../../images/preview-image.png';
import Button from './../button';
import Popup from './../popup';

const ProjectMain = ({ data, lang, translations }) => {
    let [ copyTextBtn, setCopyTextBtn ] = useState(translations.copy_project_url);
    let [ popupUrl, setPopupUrl ] = useState(false);

    const copyUrl = async (data) => {
        // await navigator.clipboard.writeText(`${window.location.origin}/${lang}/${data.slug}`);
        await navigator.clipboard.writeText(`${data}`);
        setCopyTextBtn(translations.copied);
        setTimeout(function() {
            setCopyTextBtn(translations.copy_project_url)
        }, 5000);
    }
    console.log(data)
    return (
        <main className="project-main">
            <Breadcrumbs lang={lang} />
            <Popup url={popupUrl} close={() => setPopupUrl(false)} />
            <div className="presentation-project">
                <div className="col">
                    <div className="preview-image">
                        {data?.thumbnail_preview_replace?.url &&
                            <img src={data?.thumbnail_preview_replace?.url} alt={data?.thumbnail_preview_replace?.alt} />
                        }
                        {!data?.thumbnail_preview_replace?.url &&
                         <img src={data?.thumbnail_preview?.url} alt={data?.thumbnail_preview?.alt} />
                        }
                        <div className="play-icon" onClick={() => setPopupUrl(data.yt_url)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="154" height="100" viewBox="0 0 154 100" fill="none">
                                <g clip-path="url(#clip0_372_2119)">
                                    <path opacity="0.8" d="M150.666 14.3808C148.371 7.85385 143.794 3.95769 136.902 2.22692C127.989 0 88.5808 0 77 0C65.4269 0 26.0067 0 17.0978 2.22692C10.2102 3.95769 5.6287 7.86154 3.33795 14.3808C4.58956e-07 23.8654 0 46.6308 0 50C0 53.3692 4.58956e-07 76.1346 3.33795 85.6308C5.6287 92.1577 10.2102 96.05 17.0978 97.7769C25.9991 100 65.4269 100 77 100C88.577 100 127.989 100 136.898 97.7769C143.79 96.05 148.367 92.1654 150.662 85.6308C154 76.1346 154 53.3692 154 50C154 46.6308 154.015 23.8654 150.666 14.3808ZM60.7029 69.9577C60.7029 56.6154 60.7029 43.4 60.7029 30.0346C73.9816 36.7115 87.1678 43.3423 100.531 50.0615C87.1678 56.7346 73.997 63.3192 60.7029 69.9577Z" fill="white"/>
                                    <path opacity="0.8" d="M60.7029 69.9577C60.7029 56.6154 60.7029 43.4001 60.7029 30.0347C73.9816 36.7116 87.1678 43.3424 100.531 50.0616C87.1678 56.7347 73.997 63.3193 60.7029 69.9577Z" fill="#373737"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_372_2119">
                                    <rect width="154" height="100" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="title-wrapper">
                        <h1>{data.title}</h1>
                    </div>
                    <div className="tags-wrapper">
                        {data.tags && data.tags.map(tag => (
                            <p key={tag.term_id}>#{tag.name}</p>
                        ))}
                    </div>
                    {data.info &&
                        <div className="info-wrapper">
                            <p dangerouslySetInnerHTML={ { __html: data.info } } />
                        </div>
                    }
                    <div className="btns-wrapper">
                        <Button text={copyTextBtn} className="black" setFunction={() => copyUrl(data.voxbox)} />
                        <Button text={translations.open_voxbox} setFunction={() => window.open(data.voxbox, "_blank")} />
                    </div>
                    <div className="icons-wrapper">
                        <div className="icon">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.12117 17.5174H31.8787V29.5915H8.12117V17.5174ZM6.66663 6.66675V16.0512V17.5174V31.0574H8.33961V33.1352H13.9731V31.0574H26.0389V33.1352H31.6724V31.0574H33.3333V17.5174V16.0512V6.66675H6.66663ZM31.8787 16.0512H8.12117V8.13276H31.8787V16.0512ZM22.9316 23.5543H17.0788V25.0203H22.9316V23.5543ZM17.0788 11.3652H22.9316V12.8312H17.0788V11.3652Z" fill="#373737"/>
                                </svg>
                            </div>
                            <div className="text">
                                <p>{translations.furniture}: {data.taxonomy_meble.join(", ")}</p>
                            </div>
                        </div>
                        <div className="icon">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8639 21.4481L9.04697 24.5034L20.5003 31.7695L25.3188 28.7126L13.8639 21.4481ZM27.4022 27.3909L31.9512 24.505L27.4022 21.6269V27.3909ZM27.4022 20.0668V14.8292L14.5311 6.6665L13.5039 7.20859V20.125L8.47156 23.3165V23.3148L7.16699 24.128V24.8681L20.5003 33.3332L33.8337 24.8681V24.1175L32.7816 23.4702L27.4022 20.0668ZM16.2416 21.4283L17.633 22.3102L17.5146 22.1901L24.8411 14.7547L23.5954 13.9651L16.2416 21.4283ZM25.9499 15.4575L18.5961 22.9207L19.831 23.7034L26.0771 17.3644V15.5381L25.9499 15.4575ZM26.0771 19.1925L20.9398 24.4062L22.1848 25.1953L26.0771 21.2451V19.1925ZM26.0771 27.6624L25.6344 27.3818L26.0771 26.9326V27.6624ZM23.2936 25.8981L24.5256 26.679L26.0771 25.1044V23.0733L23.2936 25.8981ZM15.1328 20.7255L14.7777 20.5004V19.0469L21.2499 12.4785L22.4866 13.2623L15.1328 20.7255ZM14.7777 17.2188L20.141 11.7757L18.9018 10.9902L14.7777 15.1757V17.2188ZM14.7777 13.3476L17.793 10.2874L16.5439 9.49569L14.7952 11.2704L14.7777 11.2526V13.3476ZM14.7777 8.37618L15.4351 8.79287L14.7777 9.46006V8.37618Z" fill="#373737"/>
                                </svg>
                            </div>
                            <div className="text">
                                <p>{translations.wall}: {data.taxonomy_sciany.join(", ")} </p>
                            </div>
                        </div>
                        <div className="icon">
                            <div className="svg">
                                <svg width="40" height="40" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.2931 5.18882V19.8329H6.71799V5.18882H17.2931ZM18 4.5H6V20.5H18V4.5Z" fill="#373737"/>
                                    <path d="M15.3117 7.17137V17.8286H8.68835V7.17137H15.3117ZM16 6.5H8V18.5H16V6.5Z" fill="#373737"/>
                                    <path d="M13.6667 13.1667C14.219 13.1667 14.6667 12.719 14.6667 12.1667C14.6667 11.6144 14.219 11.1667 13.6667 11.1667C13.1144 11.1667 12.6667 11.6144 12.6667 12.1667C12.6667 12.719 13.1144 13.1667 13.6667 13.1667Z" fill="#373737"/>
                                </svg>
                            </div>
                            <div className="text">
                                <p>{translations.doors}: {data.taxonomy_drzwi.join(", ")}</p>
                            </div>
                        </div>
                        <div className="icon">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                                    <path d="M20.5 35L7.16663 26.7357V13.2643L20.5 5L33.8333 13.2643V26.7357L20.5 35ZM8.4897 26.0224L20.5 33.4817L32.5102 26.0224V13.9776L20.5 6.51834L8.52047 13.9776L8.4897 26.0224Z" fill="#373737"/>
                                    <path d="M8.21288 26.9291L7.5257 25.8387L19.8847 18.1655V5.98817H21.1872V18.8788L8.21288 26.9291Z" fill="#373737"/>
                                    <path d="M20.8746 17.984L20.1855 19.0822L32.8405 26.9206L33.5296 25.8224L20.8746 17.984Z" fill="#373737"/>
                                    <path d="M25.1357 20.9312L12.6193 28.9301L13.3074 29.9928L25.8237 21.994L25.1357 20.9312Z" fill="#373737"/>
                                    <path d="M27.5316 22.5687L15.0307 30.5532L15.7175 31.6146L28.2184 23.6301L27.5316 22.5687Z" fill="#373737"/>
                                    <path d="M30.3095 24.2928L17.7931 32.2917L18.4811 33.3544L30.9975 25.3555L30.3095 24.2928Z" fill="#373737"/>
                                    <path d="M22.395 19.4644L10.215 27.2483L10.903 28.311L23.083 20.5272L22.395 19.4644Z" fill="#373737"/>
                                </svg>
                            </div>
                            <div className="text">
                                <p>{translations.floor}: {data.taxonomy_podlogi.join(", ")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="size-wrapper">
                        <div className="icon">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0614 30.7265L33.3333 33.4283L30.0614 36.1265L30.0614 33.9002L17.1927 33.9002L17.1927 36.1264L13.9209 33.4282L17.1927 30.7264L17.1927 32.7603L30.0614 32.7603L30.0614 30.7265ZM6.66654 13.2719L9.3684 10L12.0703 13.2719L9.84029 13.2719L9.84029 25.6004L12.0702 25.6004L9.36839 28.8759L6.66653 25.6004L8.70033 25.6004L8.70033 13.2719L6.66654 13.2719Z" fill="#373737"/>
                                    <rect x="32.8333" y="28.3889" width="17.8741" height="17.8741" transform="rotate(180 32.8333 28.3889)" stroke="#373737"/>
                                </svg>
                            </div>
                            <div className="text">
                                <p>{data.project_size} m2</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ProjectMain;