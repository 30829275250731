import React, { useState, useEffect, useRef } from "react"
import Button from './../button';
import Get from './../../services/get';
import Checkbox from './../checkbox';
import {useSearchParams} from 'react-router-dom';
import Radio from './../radio';
import getApiUrl from "../../services/getApiUrl";

const FilterBox = ({ active, close, data, maxItems, clear, translatesActiveFilters, lang, filterProjects, perPage, translations }) => {
    let [ load, setLoad ] = useState(false);
    let [ reset, setReset ] = useState(false);
    let [ activeIndexList, setActiveIndexList ] = useState(0);
    let [ indexOptions, setIndexOptions ] = useState(-1);
    const [searchParams, setSearchParams] = useSearchParams();
    let [ filterData, setFilterData ] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if(!load) {
            setFilterData(data)
            setActiveIndexList(0)
            setLoad(true);
        } 
    }, [load])

    useEffect(() => {
        if(load) {
            setLoad(false);
        }
    }, [data])

    useEffect(() => {
        if(load) {
            if(searchParams.size == 0) {
                setReset(true);
                setLoad(false);
                wait(500);
                setReset(false);
            }
        }
    }, [searchParams])

    const wait = async ms => {
        return new Promise(resolve => {
        setTimeout(resolve, ms);
        });
    }

    const getValueFromUrlCategory = (categorySlug) => {
        let valInUrl = searchParams.get(categorySlug);
        let result = "";
        if(valInUrl) {
            valInUrl = valInUrl.split(',');
            for(let i = 0; i < valInUrl.length; i++) {
                let slug = valInUrl[i];
                for(let x = 0; x<translatesActiveFilters.length; x++) {
                    let translate = translatesActiveFilters[x];
                    if(slug == translate.slug && categorySlug == translate.categorySlug) {
                        valInUrl[i] = translate.name;
                    }
                }
            }
            result =  valInUrl.join(", ")
        }
        return result;
    }

    const back = async () => {
        setActiveIndexList(0);
        const resFilter = await fetch(`${getApiUrl()}/wp-json/v3/filter-data?lang=${lang}&perPage=${perPage}&${(window.location.search).substr(1)}`);
        const result = await resFilter.json();
        if(result) {
            setFilterData(result)
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          if(active) {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        close();
                    }
                }
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
          }
        }, [ref, active]);
      }

    return (
        <div className={`filter-box-wrapper ${active ? 'active' : ''}`}>
            <div className={`box ${active ? 'active' : ''}`} ref={wrapperRef}>
                {activeIndexList > 0 &&
                    <div className="back" onClick={() => back()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0022 2L10.7083 2.70602L1.91391 11.4986L23.9982 11.458L24 12.4565L1.90948 12.497L10.7083 21.294L10.0022 22L0 12L10.0022 2Z" fill="#373737"/>
                        </svg>
                    </div>
                }
                <div className="close" onClick={() => close()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8526 12L19.9998 19.1111L19.1875 19.9193L12.0403 12.8081L4.81198 20L3.99976 19.1919L11.2281 12L3.99976 4.80812L4.81198 4L12.0403 11.1919L19.1875 4.08075L19.9998 4.88888L12.8526 12Z" fill="#373737"/>
                    </svg>
                </div>
                <div className="top">
                    <div className="header">
                    {activeIndexList == 0 &&
                        <h3>{translations.filter}</h3>
                    }
                    {activeIndexList > 0 &&
                        <h3>{filterData[indexOptions].title}</h3>
                    }
                    </div>
                    {activeIndexList == 0 &&
                    <div className="options-wrapper">
                        {filterData && filterData.map((category, i) => (
                            <div className="option" key={i} onClick={() => {setActiveIndexList(1); setIndexOptions(i)}}>
                                <p className="name">{category.title}</p>
                                <div className="value-wrapper">
                                    <p className="value">{getValueFromUrlCategory(category.slug)}</p>
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 11.5H3V12.5H21V11.5Z" fill="#373737"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 21L12.5 3L11.5 3L11.5 21H12.5Z" fill="#373737"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    }
                    {activeIndexList == 1 &&
                        <div className={`options-wrapper`}>
                            {filterData && filterData[indexOptions].data.map((option, i) => (
                                <>
                                    {filterData[indexOptions].slug == "metraz" &&
                                        <Radio text={option.name} slug={option.slug} tax={filterData[indexOptions].slug} reset={reset} />
                                    }
                                    {filterData[indexOptions].slug != "metraz" &&
                                        <Checkbox text={option.name} slug={option.slug} tax={filterData[indexOptions].slug} reset={reset} />
                                    }
                                </>
                            ))}
                        </div>
                    }
                </div>
                <div className="bottom">
                    <p className="total">{maxItems} {translations.projects_count}</p>
                    <Button text={translations.clear_filters_btn} setFunction={() => { clear(); setFilterData(data) } } />
                    <Button text={translations.filter_btn} setFunction={() => { filterProjects(); back() }} className="black" />
                </div>
            </div>
        </div>
    )
}

export default FilterBox;