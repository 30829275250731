import React, { useState, useEffect } from 'react';
import ProjectMain from './../components/project-main';
import Gallery from './../sections/gallery';
import GalleryNew from './../sections/gallery-new';
import { useLocation } from "react-router-dom";
import getApiUrl from './../services/getApiUrl';

const Project = ( { translations } ) => {
    let [ load, setLoad ] = useState(false);
    let [ data, setData ] = useState([]);
    let [ lang, setLang ] = useState("pl");
    let location = useLocation();

    useEffect(() => {
        if(!load) {
            if(!location?.state?.data) {
                getData();
            }else{
                setData(location.state.data)
            }
            setLoad(true);
        }
    }, [load])

    const getData = async() => {
        let path = location.pathname.split('/');
        let slug =  path[path.length - 1];
        let lang = path[path.length - 2];
        if(lang == "pl" || lang == "es" || lang == "en") {
            setLang(lang);
        }
        const resPageID = await fetch(`${getApiUrl()}/wp-json/v3/get-project?slug=${slug}`);
        const result = await resPageID.json();
        if(result) {
            setData(result)
        }
    }

    return (
        <>
            {data && data.title &&
                <>
                    <ProjectMain data={data} lang={lang} translations={translations} />
                    {data.gallery && !data.gallery_new &&
                        <Gallery data={data && data.gallery} translations={translations} />
                    }
                    {data.gallery_new &&
                        <GalleryNew data={data && data.gallery_new} translations={translations} />
                    }
                </>
            }
        </>
    )
}

export default Project;