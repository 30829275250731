import React, { useEffect, useState } from "react"
import Banner from './../sections/banner';
import Projects from './../sections/projects';
import { useLocation, useSearchParams } from "react-router-dom";
import getLangFromUrl from './../services/getLangFromUrl';
import getApiUrl from './../services/getApiUrl';
import onClickScroll from './../services/onClickScroll';
import Slide from './../images/slide.png';

const Home = ( { translations } ) => {
    let [ lang, setLang ] = useState(false);
    let [ data, setData ] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    let location = useLocation();

    useEffect(() => {
        setLang(getLangFromUrl(location.pathname));
    }, [location])

    useEffect(() => {
        if(lang) {
            getData();
        }
    }, [lang])

    const getData = async() => {
        let slug = "strona-glowna";
        if(lang == "en") {
            slug = "home";
        }else if(lang == "es") {
            slug = "hogar";
        }
        const resPageID = await fetch(`${getApiUrl()}/wp-json/v3/get-page?slug=${slug}&lang=${lang}&${(window.location.search).substr(1)}`);
        const result = await resPageID.json();
        if(result && result.length > 0) {
            await setData(result)
            if(searchParams.has("s")) {
                await wait(500)
                await onClickScroll("#projects")
            }
        }
    }

    const wait = async ms => {
        return new Promise(resolve => {
        setTimeout(resolve, ms);
        });
    }

    return (
        <>
        {data && data.map((section, index) => {
            if(section.acf_fc_layout == "video_banner_section") {
                return <Banner editor={section.editor} video={section.video} type={section.type} slides={section.slider} />;
            }else if(section.acf_fc_layout == "projects_list_filter_section") {
                return <Projects perPage={section.per_page} lang={lang} data={section.data_projects} translations={translations} />;
            }
        })}
        </>
    )
}

export default Home;