import React, { useEffect, useState } from "react"
import { Routes, Route, Link, useLocation } from "react-router-dom";
import HomePage from './pages/Home';
import ProjectPage from './pages/Project';
import Layout from './components/Layout';
import getApiUrl from './services/getApiUrl';
import getLangFromUrl from './services/getLangFromUrl';

function App() {
  let [ lang, setLang ] = useState(false);
  let [ translations, setTranslations ] = useState(false);
  let location = useLocation();

  useEffect(() => {
    setLang(getLangFromUrl(location.pathname));
  }, [location])

  useEffect(() => {
    if(lang) {
        getData();
    }
  }, [lang])

  const getData = async() => {
      const resPageID = await fetch(`${getApiUrl()}/wp-json/v3/translations?lang=${lang}`);
      const result = await resPageID.json();
      if(result) {
          setTranslations(result)
      }
  }

  return (
    <Layout translations={translations} lang={ lang }>
      <Routes>
        <Route path="/" element={ <HomePage translations={translations} />} />
        <Route path="/:locale" element={ <HomePage translations={translations} />} />
        <Route path="/:locale/:slug" element={ <ProjectPage translations={translations} />} />
        <Route path="/:slug" element={ <ProjectPage translations={translations} />} />
      </Routes>
    </Layout>
  );
} 


export default App;
