const Logo = () => (
    <svg width="69" height="52" viewBox="0 0 69 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_410_7278)">
        <path d="M69 0H0V52H69V0Z" fill="#E80808"/>
        <path d="M63.8 5.2L60 26L63.8 46.8H60L57.5 33.3L55 46.8H51.2L55 26L51.2 5.2H55L57.5 19.2L60 5.2H63.8Z" fill="white"/>
        <path d="M41.2 10.2V41.7C41.2 42.7 42 43.6 43.1 43.6C44.1 43.6 45 42.8 45 41.7V10.2C45 9.2 44.2 8.3 43.1 8.3C42 8.3 41.2 9.2 41.2 10.2ZM37.4 41.8V10.2C37.4 7.1 39.9 4.5 43.1 4.5C46.2 4.5 48.8 7 48.8 10.2V41.7C48.8 44.8 46.3 47.3 43.1 47.3C40 47.4 37.4 44.9 37.4 41.8Z" fill="white"/>
        <path d="M34.9 5.2L31.3 46.8H26L22.4 5.2H26.1L28.6 34.1L31.1 5.2H34.9Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_410_7278">
        <rect width="69" height="52" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)

export default Logo;