
const Button = ({ text, setFunction, className }) => {

    return (
        <div className={`btn-wrapper ${className ? className : ''}`}>
            <input type="submit" value={text} onClick={(e) => setFunction()} />
        </div>
    )
}

export default Button;