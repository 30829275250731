import React, { useState, useEffect } from 'react';
import Image from './../../images/img-project.png';
import ImageHover from './../../images/img-project-hover.png';
import { Link, useLocation } from "react-router-dom";

const Project = ({ data, lang, translations }) => {
    let [ copyTextBtn, setCopyTextBtn ] = useState(translations.copy_project_url);

    useEffect(() => {
        setCopyTextBtn(translations.copy_project_url);
    }, [translations])

    const copyUrl = async (e, url) => {
        e.preventDefault();
        // await navigator.clipboard.writeText(`${window.location.origin}/${lang}/${data.slug}`);
        await navigator.clipboard.writeText(`${url}`);
        setCopyTextBtn(translations.copied);
        setTimeout(function() {
            setCopyTextBtn(translations.copy_project_url)
        }, 5000);
    }

    const openUrl = async (e, url) => {
        e.preventDefault();
        window.open(url, "_blank")
    }

    return (
        <div className="project-item">
            <Link to={`${window.location.origin}/${lang}/${data.slug}`} state={{data: data}}>
                <div className="image-wrapper">
                    <img className="normal" src={data?.thumbnail?.url} alt="" />
                    {data?.thumbnail_hover?.url &&
                        <img className="hover" src={data?.thumbnail_hover?.url} alt="" />
                    }
                </div>
                <div className="information-wrapper">
                    <h3 className="collection-name">{data.title}</h3>
                    <div className="icons-wrapper">
                        {data.taxonomy_meble && data.taxonomy_meble.length > 0 &&
                        <div className="icon">
                            <div className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87273 11.0104H19.1273V18.2548H4.87273V11.0104ZM4 4.5V10.1307V11.0104V19.1344H5.00379V20.381H8.38387V19.1344H15.6234V20.381H19.0035V19.1344H20V11.0104V10.1307V4.5H4ZM19.1273 10.1307H4.87273V5.37961H19.1273V10.1307ZM13.759 14.6326H10.2473V15.5122H13.759V14.6326ZM10.2473 7.31907H13.759V8.19868H10.2473V7.31907Z" fill="#373737"/>
                                </svg>
                            </div>
                            <p>{translations.furniture}: {data.taxonomy_meble.join(", ")}</p>
                        </div>
                        }
                        {data.taxonomy_drzwi && data.taxonomy_drzwi.length > 0 &&
                        <div className="icon">
                            <div className="svg-icon">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.2931 5.18882V19.8329H6.71799V5.18882H17.2931ZM18 4.5H6V20.5H18V4.5Z" fill="#373737"/>
                                    <path d="M15.3117 7.17137V17.8286H8.68835V7.17137H15.3117ZM16 6.5H8V18.5H16V6.5Z" fill="#373737"/>
                                    <path d="M13.6667 13.1667C14.219 13.1667 14.6667 12.719 14.6667 12.1667C14.6667 11.6144 14.219 11.1667 13.6667 11.1667C13.1144 11.1667 12.6667 11.6144 12.6667 12.1667C12.6667 12.719 13.1144 13.1667 13.6667 13.1667Z" fill="#373737"/>
                                </svg>
                            </div>
                            <p>{translations.doors}: {data.taxonomy_drzwi.join(", ")}</p>
                        </div>
                        }
                        {data.taxonomy_sciany && data.taxonomy_sciany.length > 0 &&
                        <div className="icon">
                            <div className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M19.2173 14.4889V14.983L12 19.5617L4.78274 14.983V14.4889L4 14.9768V15.4209L12 20.4999L20 15.4209V14.9705L19.2173 14.4889Z" fill="#373737"/>
                                    <path d="M11.6092 10.1608L4.18237 14.8708L4.59532 15.5401L12.3858 10.5986L11.6092 10.1608Z" fill="#373737"/>
                                    <path d="M12.2058 10.0504L11.7917 10.7245L19.3965 15.5359L19.8106 14.8618L12.2058 10.0504Z" fill="#373737"/>
                                    <path d="M15.7263 9.60988L8.18237 4.82483V13.0126L15.7263 17.7977V9.60988Z" fill="white"/>
                                    <path d="M15.5247 18.1296L7.802 13.2319V4.82525L8.41833 4.5L16.141 9.39764V17.7981L15.5247 18.1296ZM8.56625 12.8003L15.3459 17.0975V9.82297L8.56625 5.52581V12.8003Z" fill="#373737"/>
                                    <path d="M9.18257 5.55063L8.03638 6.71387L8.57678 7.26231L9.72297 6.09907L9.18257 5.55063Z" fill="#373737"/>
                                    <path d="M10.4619 6.58534L7.91675 9.16833L8.45715 9.71677L11.0023 7.13378L10.4619 6.58534Z" fill="#373737"/>
                                    <path d="M11.9846 7.36239L7.91406 11.4934L8.45447 12.0418L12.525 7.91083L11.9846 7.36239Z" fill="#373737"/>
                                    <path d="M13.5102 8.13439L8.677 13.0394L9.21741 13.5879L14.0506 8.68283L13.5102 8.13439Z" fill="#373737"/>
                                    <path d="M14.7886 9.16558L10.2083 13.8141L10.7487 14.3625L15.3291 9.71402L14.7886 9.16558Z" fill="#373737"/>
                                    <path d="M15.5471 10.7147L11.4766 14.8457L12.017 15.3941L16.0875 11.2631L15.5471 10.7147Z" fill="#373737"/>
                                    <path d="M15.4195 13.1724L13.0051 15.6227L13.5455 16.1711L15.9599 13.7208L15.4195 13.1724Z" fill="#373737"/>
                                    <path d="M15.5473 15.3586L14.4011 16.5219L14.9415 17.0703L16.0877 15.9071L15.5473 15.3586Z" fill="#373737"/>
                                </svg>
                            </div>
                            <p>{translations.wall}: {data.taxonomy_sciany.join(", ")}</p>
                        </div>
                        }
                        {data.taxonomy_podlogi && data.taxonomy_podlogi.length > 0 &&
                        <div className="icon">
                            <div className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M12 21.5L4 16.5414V8.45856L12 3.5L20 8.45856V16.5414L12 21.5ZM4.79385 16.1135L12 20.589L19.2062 16.1135V8.88655L12 4.411L4.81231 8.88655L4.79385 16.1135Z" fill="#373737"/>
                                    <path d="M4.62775 16.6574L4.21545 16.0032L11.6308 11.3993V4.0929H12.4124V11.8273L4.62775 16.6574Z" fill="#373737"/>
                                    <path d="M12.2248 11.2904L11.8113 11.9493L19.4043 16.6524L19.8178 15.9934L12.2248 11.2904Z" fill="#373737"/>
                                    <path d="M14.7815 13.0587L7.27163 17.8581L7.68444 18.4957L15.1943 13.6964L14.7815 13.0587Z" fill="#373737"/>
                                    <path d="M16.219 14.0412L8.71845 18.8319L9.13051 19.4687L16.6311 14.6781L16.219 14.0412Z" fill="#373737"/>
                                    <path d="M17.8857 15.0757L10.3759 19.875L10.7887 20.5126L18.2985 15.7133L17.8857 15.0757Z" fill="#373737"/>
                                    <path d="M13.137 12.1787L5.82903 16.849L6.24183 17.4866L13.5498 12.8163L13.137 12.1787Z" fill="#373737"/>
                                </svg>
                            </div>
                            <p>{translations.floor}: {data.taxonomy_podlogi.join(", ")}</p>
                        </div>
                        }
                    </div>
                    <div className="size-wrapper">
                        <div className="icon">
                            <div className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0369 18.9359L20 20.557L18.0369 22.1759L18.0369 20.8402L10.3157 20.8402L10.3157 22.1759L8.35259 20.557L10.3157 18.9359L10.3157 20.1562L18.0369 20.1562L18.0369 18.9359ZM3.99997 8.46315L5.62109 6.50005L7.2422 8.46315L5.90422 8.46315L5.90422 15.8603L7.24219 15.8603L5.62108 17.8256L3.99997 15.8603L5.22025 15.8603L5.22025 8.46315L3.99997 8.46315Z" fill="#373737"/>
                                    <rect x="19.625" y="17.4584" width="10.5745" height="10.5745" transform="rotate(180 19.625 17.4584)" stroke="#373737" stroke-width="0.75"/>
                                </svg>
                            </div>
                            <p>{data.project_size} m2</p>
                        </div>
                    </div>
                    <div className="links-wrapper">
                        <div className="project-link">
                            {/* <Link to={"/"}>
                                <a>Czytaj o projekcie</a>
                            </Link> */}
                            <a onClick={(e) => copyUrl(e, data.voxbox)}>{copyTextBtn}</a>
                        </div>
                        <div className="voxbox-link">
                            <a onClick={(e) => openUrl(e, data.voxbox)}>
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                        <path d="M20 38.8333L3.33337 28.7312V12.2687L20 2.16663L36.6667 12.2687V28.7312L20 38.8333ZM5.00004 27.8582L20 37.0873L35 27.983V13.1417L20 4.03737L5.00004 13.1417V27.8582Z" fill="#373737"/>
                                        <path d="M4.61543 28.9807L3.71799 27.6088L19.1026 18.2551V3.41379H20.7693V19.1281L4.61543 28.9807Z" fill="#373737"/>
                                        <path d="M20.4465 18.0756L19.5648 19.4515L35.3401 29.0178L36.2218 27.642L20.4465 18.0756Z" fill="#373737"/>
                                    </svg>
                                </div>
                                <p>{translations.open_voxbox}</p>
                            </a>
                        </div>
                        <Link to={`${window.location.origin}/${lang}/${data.slug}`} className="tablet-url">
                            {translations.read_project}
                        </Link>
                    </div>  
                </div>
            </Link>
        </div>
    )
}

export default Project;