import { useState, useEffect } from "react"
import {useSearchParams} from 'react-router-dom';
import Get from './../../services/get';

export default props => {
    let [ value, setValue ] = useState(false);
    let [ load, setLoad ] = useState( false );
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if ( !load ) {
            setValue(getValue());
            setLoad(true);
        }
    }, [load])

    const changeVal = (val) => {
        setValue(val);
        let valInUrl = searchParams.get(props.tax);
        if(val) {
            if(searchParams.has(props.tax)) {
                let array = valInUrl.split(',');
                array = [...array, props.slug];
                searchParams.set(props.tax, array.toString())
                setSearchParams(searchParams);
            }else{
                searchParams.set(props.tax, props.slug)
                setSearchParams(searchParams);
            }
        }else{
            // usuwanie checknietego
            let array = valInUrl.split(',');
            let index = array.indexOf(props.slug);
            if (index !== -1) {
                array.splice(index, 1);
            }
            searchParams.set(props.tax, array.toString())
            if(array.length == 0) {
                searchParams.delete(props.tax);
            }
            setSearchParams(searchParams);
        }
    }

    const removeFromUrlTax = () => {
        searchParams.delete(props.tax);
        setSearchParams(searchParams);
    }

    const getValue = () => {
        let val = Get(props.tax);
        if(val ) {
            if(val.includes(props.slug)) {
                return true;
            }else{
                return false;
            }
        }else 
        return false;
    }
// tax, slug
  return (
      <div className="checkbox-wrapper">
        <label className={ "checkbox"}>
            <input type="checkbox" checked={value} onChange={ e => { changeVal(e.target.checked) }} />
            <div className="icon">
                {!value &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 4H4V20H20V4ZM3 3V21H21V3H3Z" fill="#CCCCCC"/>
                    </svg>
                }
                {value &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="3" y="3" width="18" height="18" fill="#E80808"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9154 9L10.8462 13.897L8.08462 11.2367L7 12.2845L10.8462 16L17 10.0552L15.9154 9Z" fill="white"/>
                    </svg>
                }
            </div>
            <span dangerouslySetInnerHTML={ { __html: props.text } } />
        </label>
      </div>
  )
}
