import Button from './../button';

const Pagination = ({perPage, currentPage, lastPage, changePage, maxItems, translations }) => {
    
    return (
        <div className="pagination-wrapper">
            <div className="line-wrapper">
                {currentPage < lastPage &&
                    <div className="progress" style={ { width: ((currentPage * perPage) / maxItems) * 100 + "%"} } />
                }
                {currentPage == lastPage &&
                    <div className="progress" style={ { width: 100 + "%"} } />
                }   
            </div>
            <div className="total">
                {currentPage < lastPage &&
                    <p>{currentPage * perPage} z {maxItems} {translations.projects}</p>
                }
                {currentPage == lastPage &&
                <p>{maxItems} z {maxItems} {translations.projects}</p>
                }
            </div>
            {currentPage < lastPage &&
                <Button text={translations.see_more} setFunction={() => changePage()} />
            }
        </div>
    )
}

export default Pagination;