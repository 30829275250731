import React, { useState, useRef, useEffect } from 'react';
import { ReactSmartScroller } from 'react-smart-scroller'
import GalleryImg from './../../images/gallery-img.png';
import Arrow from './../../svg-components/Arrow';

const Gallery = ({ data, translations }) => {
    let [ scrollImageIndex, setScrollImageIndex ] = useState(0);
    let ref = useRef();

    const renderImages = () => {
    
        return data.map((image, index) => (
            <img
                className="scroll-image"
                key={index}
                src={image.sizes['gallery-0x900']}
                alt={image.alt}
                style={{
                    width: '100%',
                    height: 900,
                    objectFit: 'cover'
                }}
            />
        ))
    }

    const goTo = (where) => {
        let el = document.getElementsByClassName('scrollable-wrapper');
        if(el) {
            el = el[0];
            let scrollLeft = el.scrollLeft;
            let images = document.getElementsByClassName('scroll-image');
            let actualIndex = scrollImageIndex;
            if(where == "next") {
                actualIndex += 1;
                setScrollImageIndex(actualIndex);
                let widthImage = images[actualIndex].offsetWidth + 30;
                el.scrollTo({
                    top: 0,
                    left: scrollLeft + widthImage,
                    behavior: 'smooth'
                });
            }else{
                actualIndex -= 1;
                setScrollImageIndex(--scrollImageIndex);
                let widthImage = images[actualIndex].offsetWidth + 30;
                el.scrollTo({
                    top: 0,
                    left: scrollLeft - widthImage,
                    behavior: 'smooth'
                });
            }
        }
    }

    const scrollbarX = (event) => {
        // if (event.stopPropagation) {
        //     event.stopPropagation();
        // } else {
        //     event.cancelBubble = true;
        // }
    }

    return (
        <section className="gallery">
            <div className="title-wrapper">
                <h2>{translations.project_image}</h2>
            </div>
            <div className="gallery-wrapper">
                {scrollImageIndex > 0 &&
                    <div className="arrow left" onClick={() => goTo("prev")}>
                        <Arrow />
                    </div>
                }
                <div ref={ref} className="scrollable-wrapper" scroll={"disable"} onScroll={ (e) => scrollbarX(e)}>
                    {renderImages()}
                </div> 
                {scrollImageIndex < data.length - 1 &&
                    <div className="arrow right" onClick={() => goTo("next")}>
                        <Arrow />
                    </div>
                }
            </div>
        </section>
    )
}

export default Gallery;