import React, { useState, useRef, useEffect } from 'react';
import { ReactSmartScroller } from 'react-smart-scroller'
import GalleryImg from './../../images/gallery-img.png';
import Arrow from './../../svg-components/Arrow';

const GalleryNew = ({ data, translations }) => {
    let [ scrollImageIndex, setScrollImageIndex ] = useState(0);
    let ref = useRef();

    const openPage = (url) => {
        window.open(url, "_blank");
    }

    const renderImages = () => {
           return data.map((image, index) => (
            <div className={`parent-scroll-image ${image.link && "link"}`} style={{
                width: '100%',
                height: 900,
                objectFit: 'cover',
                display: 'block',
            }}>
                <img
                    className={`scroll-image`}
                    onClick={() => image.link && openPage(image.link)}
                    key={index}
                    src={image.image.sizes['gallery-0x900']}
                    alt={image.image.alt}
                    style={{
                        // width: '100%',
                        height: 900,
                        objectFit: 'cover'
                    }}
                />
                {image.link &&
                    <div className="basket-wrapper">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 44 32"><path d="m43.631.925-4.986 18.85H13.698L9.409 3.028H1.331v-2h9.63l4.289 16.747h21.855L41.697.414zM14.899 26.816c0-2.847 2.271-5.187 5.112-5.187s5.112 2.339 5.112 5.187c0 2.847-2.272 5.187-5.112 5.187s-5.112-2.339-5.112-5.187m5.112-3.186c-1.701 0-3.112 1.41-3.112 3.187s1.41 3.187 3.112 3.187c1.701 0 3.112-1.41 3.112-3.187s-1.41-3.187-3.112-3.187m7.217 3.186c0-2.847 2.272-5.187 5.112-5.187s5.112 2.339 5.112 5.187c0 2.847-2.272 5.187-5.112 5.187s-5.112-2.339-5.112-5.187m5.111-3.186c-1.701 0-3.112 1.41-3.112 3.187s1.41 3.187 3.112 3.187c1.701 0 3.112-1.41 3.112-3.187s-1.41-3.187-3.112-3.187"></path></svg>
                        </div>
                        <div className="text">
                            {translations.see_products}
                        </div>
                    </div>
                    
                }
            </div>
            ))
    }

    const goTo = (where) => {
        let el = document.getElementsByClassName('scrollable-wrapper');
        if(el) {
            el = el[0];
            let scrollLeft = el.scrollLeft;
            let images = document.getElementsByClassName('scroll-image');
            let actualIndex = scrollImageIndex;
            if(where == "next") {
                actualIndex += 1;
                setScrollImageIndex(actualIndex);
                let widthImage = images[actualIndex].offsetWidth + 30;
                el.scrollTo({
                    top: 0,
                    left: scrollLeft + widthImage,
                    behavior: 'smooth'
                });
            }else{
                actualIndex -= 1;
                setScrollImageIndex(--scrollImageIndex);
                let widthImage = images[actualIndex].offsetWidth + 30;
                el.scrollTo({
                    top: 0,
                    left: scrollLeft - widthImage,
                    behavior: 'smooth'
                });
            }
        }
    }

    const scrollbarX = (event) => {
        // if (event.stopPropagation) {
        //     event.stopPropagation();
        // } else {
        //     event.cancelBubble = true;
        // }
    }

    return (
        <section className="gallery">
            <div className="title-wrapper">
                <h2>{translations.project_image}</h2>
            </div>
            <div className="gallery-wrapper">
                {scrollImageIndex > 0 &&
                    <div className="arrow left" onClick={() => goTo("prev")}>
                        <Arrow />
                    </div>
                }
                <div ref={ref} className="scrollable-wrapper" scroll={"disable"} onScroll={ (e) => scrollbarX(e)}>
                    {renderImages()}
                </div> 
                {scrollImageIndex < data.length - 1 &&
                    <div className="arrow right" onClick={() => goTo("next")}>
                        <Arrow />
                    </div>
                }
            </div>
        </section>
    )
}

export default GalleryNew;