const getTitleFromSlug = (items, categorySlug, slug) => {
    if(items && items.length > 0 && categorySlug && slug) {
        let res = [slug];
        items.filter(cat => {
            if(cat.slug == categorySlug) {
                res = cat && cat.data && cat.data.length > 0 && cat.data.filter(item => item.slug == slug ? item.name : null);
            }
        });

        return res[0];
    }else{
        return slug;
    }
} 

export default getTitleFromSlug;