import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Breadcrumbs = ({lang}) => {
    
    return (
        <div className="breadcrumbs-wrapper">
            <Link to={`/${lang}`}>
            <div className="item-list">
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50012 2.00024L7.2063 2.70626L1.91403 7.99856L16.0001 8.00001L16.002 8.99847L1.9096 8.99704L7.2063 14.2942L6.50012 15.0002L0.00012207 8.50001L6.50012 2.00024Z" fill="#373737"/>
                    </svg>
                </div>
                <p>Wróć do listy projektów</p>
            </div>
            </Link>
        </div>
    )
}

export default Breadcrumbs;