
const Popup = ( { url, close } ) => {

    return (
        <div className={`popup ${url ? 'active' : ''}`}>
            <div className="close-popup" onClick={() => close()}>X</div>
            <div className="container">
                <div className="wrapper">
                    <iframe width="1280" height="720" src={`${url}&autoplay=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Popup;