import React, { useState, useEffect } from "react"
import Logo from './../../svg-components/Logo';
import SearchIcon from './../../svg-components/SearchIcon';
import PlFlag from './../../images/flag-pl.png';
import EnFlag from './../../images/flag-en.png';
import EsFlag from './../../images/flag-es.png';
import { Link, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import onClickScroll from './../../services/onClickScroll';

const Header = ( { lang, translations } ) => {
    let [ activeToggleLang, setActiveToggleLang ] = useState(false);
    let [ searchVal, setSearchVal ] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    let location = useLocation();

    const handle = (key) => {
        if(key == "Enter") {
            search();
        }
    }

    const search = () => {
        if(searchVal && searchVal.length >= 2) {
            // searchParams.set("s", searchVal);
            // setSearchParams(searchParams);
            navigate(`/${lang}?s=${searchVal}`);
            // console.log(location);
            onClickScroll("#projects")
        }else if(searchVal.length == 0) {
            searchParams.delete("s");
            setSearchParams(searchParams);
        }
    }

    return (
        <header className={activeToggleLang ? 'lang-switcher' : ''}>
            <Link to={`/${lang}`}>
                <div className="logo-wrapper">
                    <Logo />
                </div>
            </Link>
            <div className="search-wrapper">
                <input type="text" placeholder={translations.search} value={searchVal} onChange={(e) => setSearchVal(e.target.value)} onKeyDown={(e) => handle(e.key)}  />
                <SearchIcon />
            </div>
            <div className="actions-wrapper">
                <div className="flags">
                    <div className="other-flags">
                        {lang != "en" &&
                            <Link to={"/en"} className="flag" onClick={() => setActiveToggleLang(false)}>
                                <img src={EnFlag} alt="en" />
                            </Link>
                        }
                        {lang != "es" &&
                            <Link to={"/es"} className="flag" onClick={() => setActiveToggleLang(false)}>
                                <img src={EsFlag} alt="es" />
                            </Link>
                        }
                        {lang != "pl" &&
                            <Link to={"/pl"} className="flag" onClick={() => setActiveToggleLang(false)}>
                                <img src={PlFlag} alt="pl" />
                            </Link>
                        }
                    </div>
                    <div className="flag active-lang" onClick={() => setActiveToggleLang(!activeToggleLang)}>
                        {lang == "pl" &&
                            <img src={PlFlag} alt="pl" />
                        }
                        {lang == "en" &&
                            <img src={EnFlag} alt="en" />
                        }
                        {lang == "es" &&
                            <img src={EsFlag} alt="es" />
                        }
                    </div>
                </div>
                <div className="link">
                    <a href="https://app.voxbox.pl/">VOXBOX</a>
                </div>
            </div>
        </header>
    )
}

export default Header;